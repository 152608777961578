import {createRouter, createWebHashHistory} from "vue-router";
const routes = [
    {
        path: '/',
        component: () => import('../components/StartPage')
    },
    {
        path: '/spaces',
        component: () => import('../components/SpacesPage')
    },
    {
        path: '/matrix/:spaceId',
        component: () => import('../components/MatrixPage')
    },
    {
        path: '/details/:id',
        component: () => import('../components/DetailsPage')
    },
    {
        path: '/media',
        component: () => import('../components/MediaPage')
    }
];
const router = createRouter({
    history: createWebHashHistory(),
    routes
});
export default router;
