<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@font-face {
  font-family: 'D-DIN-Regular';
  src: url("assets/fonts/D-DIN.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: 'D-DIN-Bold';
  src: url("assets/fonts/D-DIN-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: 'D-DIN-Italic';
  src: url("assets/fonts/D-DIN-Italic.otf") format("opentype");
  font-style: normal;
}

body {
  background-color: #0c254c;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #ffffff;
  margin-top: 60px;
}
</style>
